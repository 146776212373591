<template>
<div>
  <p class="title is-2">Registru</p>
  <p class="subtitle">Agricol</p>

  <section class="section is-medium">
    <h1 class="title">In Constructie.</h1>
    <h2 class="subtitle">
      Reveniti mai tarziu.
    </h2>
  </section>

</div>
</template>

<script>
export default {
  name: "RegistruAgricol"
}
</script>

<style scoped>

</style>